import React from "react";
import { FaClock, FaAnglesUp, FaMapLocation, FaPhone, FaFacebookMessenger, FaTwitter, FaVimeo, FaWifi, FaSkype } from 'react-icons/fa6';

//import  {FaUser, FaHouse, FaAngleDown, FaRegHandshake, FaSquareCaretLeft} from 'react-icons/fa6';

import logo from '../img/logo.png';
import eventimg from './img/event.png';
import Slider from './Slider';


const Header = ( props) => {

//[End] --- Map Constant
return (
<>
	
		{/*{/*  <!-- ==========Preloader Overlay Starts Here========== --> */}
    <div className="overlayer" style={{display: 'none'}} >
        <div className="loader">
            <div className="loader-inner"></div>
        </div>
    </div>
    <div className="scrollToTop"><FaAnglesUp/></div>
    <div className="overlay"></div>
    <div className="overlayTwo"></div>
	{/*  <!-- ==========Preloader Overlay Ends Here========== --> */}
	
	{/*  <!-- ==========Header Section Starts Here========== --> */}
    <header className="">
        <div className="header-top">
            <div className="container">
                <div className="header-top-area">
                    <ul className="left">
                        <li>
                            <FaClock/> 9:30am - 6:30pm Mon - Sun
                        </li>
                        <li>
                            <a href="/About"><FaPhone/> +800-123-4567 6587</a>
							
                        </li>
                        <li>
                            <FaMapLocation/> Beverley, New York 224 US
                        </li>
                    </ul>
                    <ul className="social-icons">
                        <li>
                            <a href="/About"><FaFacebookMessenger/></a>
                        </li>
                        <li>
                            <a href="/About"><FaTwitter/></a>
                        </li>
                        <li>
                            <a href="/About"><FaVimeo/></a>
                        </li>
                        <li>
                            <a href="/About"><FaSkype/></a>
                        </li>
                        <li>
                            <a href="/About"><FaWifi/></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="header-bottom">
            <div className="container">
                <div className="header-wrapper">
                    <div className="logo">
                        <a href="/About">
                            <img src={logo} alt="logo" />
                        </a>
                    </div>
                    <div className="menu-area">
                        <ul className="menu">
							<li className="menu-item-has-children">
                                <a href="/">
									<i className="fas fa-home"></i>Home
									<i className="fas fa-caret-right rightArraow"></i>
								</a>
								
                                <ul className="submenu">
                                    <li>
                                        <a href="/Home" className="active">Home One</a>
                                    </li>
                                    <li>
                                        <a href="/Home">Home Two</a>
                                    </li>
                                    <li>
                                        <a href="/Home">Home Three</a>
                                    </li>
                                </ul>
                            </li>							
                            
                            <li>
                                <a href="/About"><i className="fas fa-user"></i>About</a>
                            </li>
							<li>
                                <a href="/Gallery"><i className="fas fa-user"></i>Gallery</a>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="/About"><i className="fas fa-book"></i>Classes
								<i className="fas fa-caret-right rightArraow"></i>
								</a>
								
                                <ul className="submenu">
                                    <li>
                                        <a href="/About">Classes</a>
                                    </li>
                                    <li>
                                        <a href="/About">Class Single</a>
                                    </li>
                                    <li>
                                        <a href="/About">Class Schedule</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#About"><i className="fas fa-pencil-alt"></i>Blog<i className="fas fa-caret-right rightArraow"></i></a>
								
                                <ul className="submenu">
                                    <li>
                                        <a href="/About">Blog Grid</a>
                                    </li>
                                    <li>
                                        <a href="/About">Blog Classic</a>
                                    </li>
                                    <li>
                                        <a href="/About">Blog Single</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#About"><i className="fas fa-layer-group"></i>Pages<i className="fas fa-caret-right rightArraow"></i></a>
								
                                <ul className="submenu">
                                    <li className="menu-item-has-children">
                                        <a href="/About">Teacher</a>
                                        <ul className="submenu">
                                            <li>
                                                <a href="/About">Our Teacher</a>
                                            </li>
                                            <li>
                                                <a href="/About">Teacher Single</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="/About">Account</a>
                                        <ul className="submenu">
                                            <li>
                                                <a href="/About">Sign In</a>
                                            </li>
                                            <li>
                                                <a href="/About">Sign Up</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="/About">Events</a>
                                        <ul className="submenu">
                                            <li>
                                                <a href="/About">Our Events</a>
                                            </li>
                                            <li>
                                                <a href="/About">Events Single</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="/About">Shop</a>
                                        <ul className="submenu">
                                            <li>
                                                <a href="/About">Our Shop</a>
                                            </li>
                                            <li>
                                                <a href="/About">Shop Single</a>
                                            </li>
                                            <li>
                                                <a href="/About">Cart</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/About">Faqs</a>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href="/About">Our Gallery</a>
                                        <ul className="submenu">
                                            <li>
                                                <a href="/About">Gallery One</a>
                                            </li>
                                            <li>
                                                <a href="/About">Gallery Two</a>
                                            </li>
                                            <li>
                                                <a href="/About">Gallery Three</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/About">Coming Soon</a>
                                    </li>
                                    <li>
                                        <a href="/About">404 Error</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/Contact"><i className="fas fa-map-marker-alt"></i>Contact</a>
                            </li>
                        </ul>
                        <div className="search-button">
                            <a href="/About">
                                <i className="fas fa-search"></i>
                            </a>
                        </div>
                        <div className="cart-button">
                            <a href="#a">
								
								 <img src={eventimg} alt="logo" />
                              
                            </a>
                        </div>
                        <div className="header-bar d-lg-none">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="ellepsis-bar d-lg-none">
                            <i className="fas fa-ellipsis-h"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>{/*  <!-- ==========Header Section Ends Here========== --> */}{/*  <!-- ===========Header Search=========== --> */}
    <div className="header-form">
        <div className="bg-lay">
            <div className="cross">
                <i className="fas fa-times"></i>
            </div>
        </div>
        <form className="form-container">
            <input type="text" placeholder="Input Your Search" name="name" />
            <button type="submit">Search</button>
        </form>
    </div>{/*  <!-- ===========Header Search=========== --> */}
	
	{/*  <!-- ===========Header Cart=========== --> */}
    <div className="cart-sidebar-area">
        <div className="top-content">
            <a href="/" className="logo">
                <img src={logo} alt="logo" />
            </a>
            <span className="side-sidebar-close-btn"><i className="fas fa-times"></i></span>
        </div>
        <div className="bottom-content">
            <div className="cart-products">
                <h4 className="title">New Events Running in MPS</h4>
				
				<div className="single-product-item">
                    <div className="thumb">
                        <img src="./kitton/list/2.png" alt="shop" />
                    </div>
                    <div className="content">
                        <h4 className="title">Tommorrow Fun Holiday</h4>
                        <div className="price"><span className="pprice"> Holiday <FaClock/> Guru Purnima Dated: 20-08-2023 </span></div>
                        <a href="/About" className="remove-cart">Remove</a>
                    </div>
                </div>
				
				 <div className="single-product-item">
                    <div className="thumb">
                        <img src="./kitton/list/3.png" alt="shop" />
                    </div>
                    <div className="content">
                        <h4 className="title">School Art Event</h4>
                        <div className="price"><FaClock/> Art and Design Compitition on Dated: 20-08-2023</div>
                        <a href="/About" className="remove-cart">Remove</a>
                    </div>
                </div>
				
                <div className="single-product-item">
                    <div className="thumb">
                        <img src="./kitton/list/1.png" alt="shop" />
                    </div>
                    <div className="content">
                        <h4 className="title">Game Play Time</h4>
                        <div className="price"><span className="pprice"> <FaClock/> 9:30am - 6:30pm Mon - Sun</span></div>
                        <a href="/About" className="remove-cart">Remove</a>
                    </div>
                </div>
                
               
                <div className="single-product-item">
                    <div className="thumb">
                        <img src="./kitton/list/4.png" alt="shop" />
                    </div>
                    <div className="content">
                        <h4 className="title">Stop Watch</h4>
                        <div className="price"><span className="pprice">$80.00</span> <del className="dprice">$120.00</del></div>
                        <a href="/About" className="remove-cart">Remove</a>
                    </div>
                </div>
                <div className="single-product-item">
                    <div className="thumb">
                        <img src="./kitton/list/5.png" alt="shop" />
                    </div>
                    <div className="content">
                        <h4 className="title">Comics Book</h4>
                        <div className="price"><span className="pprice">$80.00</span> <del className="dprice">$120.00</del></div>
                        <a href="/About" className="remove-cart">Remove</a>
                    </div>
                </div>
               
            </div>
        </div>
    </div>{/*  <!-- ===========Header Cart=========== --> */}
	
	{/*  <!-- ==========Carousel Section Starts Here========== --> */}			
	<div className="owl-carousel header-carousel position-relative owl-loaded owl-drag">							
		<Slider/>					
	</div>
	{/*  <!-- ==========Carousel Section End Here========== --> */}
	
</>
  );
};
export default Header;