import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
 
import carousel1 from './img/carousel-1.jpeg';
import carousel2 from './img/carousel-2.jpeg';
import carousel3 from './img/carousel-3.jpeg';



const AutoplaySlider = withAutoplay(AwesomeSlider);
 const Slider = (props) => {

return (
		<>
		<span className="beforeCarousel"></span>
		 <AutoplaySlider
    play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={6000}
	
  >
    			
    <div data-src={carousel1} />
    <div data-src={carousel2} />
    <div data-src={carousel3} />
	
  </AutoplaySlider>
  <span className="afterCarousel"></span>
  </>
		);
 };
export default Slider;