import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import testimonial1 from './img/testimonial-1.jpg';
import testimonial2 from './img/testimonial-2.jpg';
import testimonial3 from './img/testimonial-3.jpg';

/*const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }
];
*/	
const GalleryParents = () => {
    return (
        <div>
			 <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s"  style={{maxWidth:'600px'}} >
				<h1 className="mb-3">Our Parents Say!</h1>
				<p>Discovery and exploration, language development, new-age skills, and shaping scientific mindset.</p>
			</div>
			<div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
				
				
					   <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} responsive={[{
						  breakpoint: 800,
						  settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						  }
						}, {
						  breakpoint: 500,
						  settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						  }
						}]}>
						<div style={{
							textAlign: 'center',
							background: 'none',
							padding: '0px 0',
							fontSize: '30px'
						}}>
						
								 <div className="testimonial-item bg-light rounded p-5">
									<p className="fs-5">Modern Public School is a unique and incomparable option for me as a parent. I have faith in Modern Public School
										and feel secure knowing my children are in their care.</p>
									<div className="d-flex align-items-center bg-white me-n5"  style={{borderRadius:'50px 0 0 50px'}}>
										<img className="img-fluid flex-shrink-0 rounded-circle" src={testimonial1} style={{width:'90px', height:'90px' }} alt="" />
										<div className="ps-3">
											<h3 className="mb-1">Parents Name</h3>
											<span>Govt. Employee</span>
										</div>
										<i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
									</div>
								</div>
								
						
						</div>
						
						<div style={{
							textAlign: 'center',
							background: 'none',
							padding: '0px 0',
							fontSize: '30px'
						}}>
								<div className="testimonial-item bg-light rounded p-5">
									<p className="fs-5">Modern Public School will help your child develop socially and emotionally. In Modern Public School, your children will learn how to compromise, be respectful towards others, and solve problems. Modern Public School will provide a place where your child will gain a sense of self, explore, play with their peers, and build confidence. </p>
									<div className="d-flex align-items-center bg-white me-n5"  style={{borderRadius:'50px 0 0 50px'}}>
										<img className="img-fluid flex-shrink-0 rounded-circle" src={testimonial2} style={{width:'90px', height:'90px' }} alt="" />
										<div className="ps-3">
											<h3 className="mb-1">Parents Name</h3>
											<span>Profession</span>
										</div>
										<i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
									</div>
								</div>
						</div>
						
						<div style={{
							textAlign: 'center',
							background: 'none',
							padding: '0px 0',
							fontSize: '30px'
						}}>
								<div className="testimonial-item bg-light rounded p-5">
									<p className="fs-5">Modern Public School play school instils a spirit of joy and fervour in children for learning new things and outshine in the competitive world. Being one of the most renowned nursery schools, Modern Public School has all that a growing toddler needs to complete his early education!</p>
									<div className="d-flex align-items-center bg-white me-n5"  style={{borderRadius:'50px 0 0 50px'}}>
										<img className="img-fluid flex-shrink-0 rounded-circle" src={testimonial3} style={{width:'90px', height:'90px' }} alt="" />
										<div className="ps-3">
											<h3 className="mb-1">Parents Name</h3>
											<span>Profession</span>
										</div>
										<i className="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
									</div>
								</div>
						</div>
								
							
							</Slide>
					
			</div>		
        </div>
    );
};

export default GalleryParents;