import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

//import testimonial1 from './img/testimonial-1.jpg';
//import testimonial2 from './img/testimonial-2.jpg';
//import testimonial3 from './img/testimonial-3.jpg';

import team0 from './img/team-0.jpg';
import team1 from './img/team-1.jpg';
import team2 from './img/team-2.jpg';
import team3 from './img/team-3.jpg';
/*
const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }
];
*/
const GalleryTeachers = () => {
    return (
        <div>
			<div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s"  style={{maxWidth:'600px'}} >
				<h1 className="mb-3">Popular Teachers</h1>
				<p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
					eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
			</div>
			<div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
				
				
					   <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} responsive={[{
						  breakpoint: 800,
						  settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						  }
						}, {
						  breakpoint: 500,
						  settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						  }
						}]}>
						<div style={{
							textAlign: 'center',
							background: 'none',
							padding: '0px 0',
							fontSize: '30px'
						}}>
						
								 <div className="row g-4">
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
										<div className="team-item position-relative">
											<img className="img-fluid rounded-circle w-75" src={team0} alt="" />
											<div className="team-text">
												<h3>Full Name</h3>
												<p>Designation</p>
												<div className="d-flex align-items-center">
													<a className="btn btn-square btn-primary mx-1" href="#a"><i className="fab fa-facebook-f"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-twitter"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-instagram"></i></a>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
										<div className="team-item position-relative">
											<img className="img-fluid rounded-circle w-75" src={team2} alt="" />
											<div className="team-text">
				
												<h3>Full Name</h3>
												<p>Designation</p>
												<div className="d-flex align-items-center">
													<a className="btn btn-square btn-primary mx-1" href="#a"><i className="fab fa-facebook-f"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-twitter"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-instagram"></i></a>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
										<div className="team-item position-relative">
											<img className="img-fluid rounded-circle w-75" src={team3} alt="" />
											<div className="team-text">
												<h3>Full Name</h3>
												<p>Designation</p>
												<div className="d-flex align-items-center">
													<a className="btn btn-square btn-primary mx-1" href="#a"><i className="fab fa-facebook-f"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-twitter"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-instagram"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
								
						
						</div>
						
						<div style={{
							textAlign: 'center',
							background: 'none',
							padding: '0px 0',
							fontSize: '30px'
						}}>
								
								 <div className="row g-4">
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
										<div className="team-item position-relative">
											<img className="img-fluid rounded-circle w-75" src={team1} alt="" />
											<div className="team-text">
												<h3>Full Name</h3>
												<p>Designation</p>
												<div className="d-flex align-items-center">
													<a className="btn btn-square btn-primary mx-1" href="#a"><i className="fab fa-facebook-f"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-twitter"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-instagram"></i></a>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
										<div className="team-item position-relative">
											<img className="img-fluid rounded-circle w-75" src={team2} alt="" />
											<div className="team-text">
				
												<h3>Full Name</h3>
												<p>Designation</p>
												<div className="d-flex align-items-center">
													<a className="btn btn-square btn-primary mx-1" href="#a"><i className="fab fa-facebook-f"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-twitter"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-instagram"></i></a>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
										<div className="team-item position-relative">
											<img className="img-fluid rounded-circle w-75" src={team3} alt="" />
											<div className="team-text">
												<h3>Full Name</h3>
												<p>Designation</p>
												<div className="d-flex align-items-center">
													<a className="btn btn-square btn-primary mx-1" href="#a"><i className="fab fa-facebook-f"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-twitter"></i></a>
													<a className="btn btn-square btn-primary  mx-1" href="#a"><i className="fab fa-instagram"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
								
								
						</div>
						
						
								
							
				</Slide>
					
			</div>		
        </div>
    );
};

export default GalleryTeachers;