//import { Link } from "react-router-dom";
import React from 'react';
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>		 	
	 <div className="container-xxl py-5">
            <div className="container">
				<Outlet />	
			</div>
	</div>
    </>
  )
};
export default Layout;