//import ReactDOM from "react-dom/client";
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Header from "./pages/Header";
import Footer from "./pages/Footer";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import MainGallery from "./pages/maingallery/MainGallery";

import About from "./pages/About";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

function App() {
  return (
     
      <BrowserRouter>
	  <Header/>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>} />
          <Route path="/About" element={<About/>} />
		 <Route path="/Gallery" element={<MainGallery/>} />
          <Route path="/Contact" element={<Contact/>} />
          <Route path="*" element={<NoPage/>} />
        </Route>
      </Routes>
	 <Footer/>
    </BrowserRouter>
   
  );
}

export default App;
