import event1 from "./images/event1.jpg";
import event2 from "./images/event2.jpg";
import event3 from "./images/event3.jpg";
import event4 from "./images/event4.jpg";
import event5 from "./images/event5.jpg";
const data =[
    {
        id:1,
        image:event1,
        title:"Birthday Events",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"birthday",
       
    },
    {
        id:2,
        image:event2,
        title:"Birthday Events",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"birthday",
       
    },
    {
        id:3,
       image:event3,
        title:"Grand Opening",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"events",
       
    },
    {
        id:4,
        image:event4,
        title:"Grand Opening",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"events",
       
    },
    {
        id:5,
        image:event5,
        title:"Grand Opening",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"events",
       
    },
    
]

export default data;