import React from 'react';


//import Container from 'react-bootstrap/Container';
const About = () => {
  return <>
  	<div className="text-left mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 'auto', visibility:'visible', animationDelay:'0.1s', animationName:'fadeInUp'}}>
	  <h5 className="mb-4">About Us</h5>  
      <p>&emsp;&emsp;&emsp;&emsp; Starting from 2023, Modern Public School has completed almost 1 years of successful years in education segment and has over 3 operating play schools across Tehsil - Shahpura, Jaipur. 
 Modern Public School: A place where eminence in learning is felt. The amazing part of this kind of learning is that this power and fun in learning is felt both by parents and the kids. They foresee a Modern Public School which is not just in learning numbers and alphabets but far beyond that. Technology which has seeped deep in the Modern Public School is a gift which will be cherished for long. 

	</p>

	</div>
</>
};

export default About;